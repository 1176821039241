// Card.js
import React, { useState } from "react";
import Topc0 from "../Images/Topc.png";
import Topc1 from "../Images/Topc1.png";
import Topc2 from "../Images/Topc2.png";
import Topc3 from "../Images/Topc3.png";
import Topc4 from "../Images/Topc4.png";
import Topc5 from "../Images/Topc5.png";
import Topc6 from "../Images/Topc6.png";
import Topc7 from "../Images/Topc7.png";
import Topc8 from "../Images/Topc8.png";
import Topc9 from "../Images/Topc9.png";
import Topc10 from "../Images/Topc10.png";
import Topc11 from "../Images/Topc11.png";
import Topc12 from "../Images/Topc12.png";
import Topc13 from "../Images/Topc13.png";
import Topc14 from "../Images/Topc14.png";
import Topc15 from "../Images/Topc15.png";
import Topc16 from "../Images/Topc16.png";
import { Link } from "react-router-dom";

const Popup = ({ image, title, text, onClose, link }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-4 w-11/12 md:w-2/3 lg:w-1/2">
        <div
          className="bg-cover bg-center rounded-t-lg h-64"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
        <div className="p-4 text-center">
          <h2 className="text-3xl font-semibold mb-4">{title}</h2>
          <p className="mb-4">{text}</p>
          <div className="flex justify-between">
            <button
              onClick={onClose}
              className="bg-gray-300 hover:bg-gray-400 text-black font-semibold py-2 px-4 rounded"
            >
              Back
            </button>
            {link ? (
              <Link
                to={link}
                className="bg-[#D8B56D] text-white uppercase bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
              >
                View Properties
              </Link>
            ) : (
              <button
                className="bg-gray-300 text-gray-600 cursor-not-allowed py-2 px-4 rounded"
                disabled
              >
                No Link Available
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};


const Card = ({ image, title, onViewDetails }) => {
  return (
    <div
      className="bg-transparent shadow-lg rounded-lg overflow-hidden flex flex-col items-center p-4 bg-cover bg-center transform transition duration-500 hover:scale-105"
      style={{
        backgroundImage: `url(${image})`,
        width: "400px",
        height: "265px",
      }}
    >
      <div className="w-[440px] rounded-lg text-center p-4 flex flex-col justify-between h-full">
        <h2 className="text-3xl text-white font-semibold mt-20 mb-1 text-shadow-md animate-fadeIn">
          {title}
        </h2>
        <button
          onClick={onViewDetails}
          className="bg-white/75 hover:bg-[#D8B56D] uppercase text-black text-xl mt-14 rounded-xl hover:text-white font-semibold px-4 py-4 w-full animate-fadeIn"
        >
          View Details
        </button>
      </div>
    </div>
  );
};

const LastCard = ({ image, title, onViewDetails }) => {
  return (
    <div
      className="bg-transparent shadow-lg rounded-lg overflow-hidden flex flex-col items-center p-4 bg-cover bg-center transform transition duration-500 hover:scale-105"
      style={{
        backgroundImage: `url(${image})`,
        width: "800px", // Double the width of a single card
        height: "265px",
      }}
    >
      <div className="w-[840px] rounded-lg text-center p-4 flex flex-col justify-between h-full">
        <h2 className="text-3xl text-white font-semibold mt-20 mb-1 text-shadow-md animate-fadeIn">
          {title}
        </h2>
        <button
          onClick={onViewDetails}
          className="bg-white/75 hover:bg-[#D8B56D] uppercase text-black text-xl mt-14 rounded-xl hover:text-white font-semibold px-4 py-4 w-full animate-fadeIn"
        >
          View Details
        </button>
      </div>
    </div>
  );
};
const FirstCard = ({ image, title }) => {
  return (
    <div
      className="bg-transparent shadow-lg rounded-lg overflow-hidden flex flex-col items-center p-4 bg-cover bg-center transform transition duration-500 hover:scale-105"
      style={{
        backgroundImage: `url(${image})`,
        width: "800px", // Double the width of a single card
        height: "265px",
      }}
    >
      <div className="w-[840px] rounded-lg text-center p-4 flex flex-col justify-between h-full">
        <h2 className="text-3xl text-white font-semibold mt-20 mb-1 text-shadow-md animate-fadeIn">
          {title}
        </h2>
        <Link to={"/RAK#top"}
          className="bg-white/75 hover:bg-[#D8B56D] uppercase text-black text-xl mt-14 rounded-xl hover:text-white font-semibold px-4 py-4 w-full animate-fadeIn"
        >
          View Details
        </Link>
      </div>
    </div>
  );
};

const images = [
  Topc1,
  Topc2,
  Topc3,
  Topc4,
  Topc0,
  Topc5,
  Topc6,
  Topc7,
  Topc8,
  Topc9,
  Topc10,
  Topc11,
  Topc12,
  Topc13,
  Topc14,
];

const titles = [
  "DownTown Dubai",
  "Dubai Marina",
  "Dubai Hills Estate",
  "Business Bay",
  "Damac Hills",
  "Al Zorah",
  "Arabian Ranches 3",
  "Emaar South",
  "Palm Jumeirah",
  "Dubai Creek Harbour",
  "Damac Lagoons",
  "Sobha Hartland",
  "Emaar Beachfront",
  "Meydan District One",
  "Tilal Al Ghaf",
];

const popupTexts = [
  "Downtown Dubai is a bustling multicultural district known for its iconic landmarks such as the Burj Khalifa, Dubai Mall, and Dubai Fountain. It offers a mix of luxury apartments, world-class shopping, dining, and entertainment options.",
  "Dubai Marina is a waterfront community famous for its breathtaking skyline, charming marina, and vibrant way of life. It has upscale eateries, entertainment venues, and waterfront apartments. Living on the waterfront with easy access to the promenade and beach is enjoyed by residents.",
  "Dubai Hills Estate is a prestigious master-planned community offering a range of residential options including villas, townhouses, and apartments. It features lush green spaces, golf courses, and an array of amenities such as schools, hospitals, and shopping centers.",
  "Business Bay is a thriving business district, Situated next to the Dubai Water Canal, It has luxury hotels, contemporary skyscrapers, and a wide range of eateries and shops. Entrepreneurs and business professionals are drawn to its dynamic atmosphere and strategic location.",
  "Damac Hills is a master-planned golf community offering a range of luxury villas, townhouses, and apartments. It boasts an 18-hole championship golf course, lush green spaces, and family-friendly amenities such as schools, parks, and sports facilities.",
  "Al Zorah in Ajman offers a peaceful diversion from the bustle of the city. In the middle of the chaos, there is tranquilly in this quiet waterfront community. Its allure is enhanced by a top-notch golf course, sophisticated beachfront apartments, and sumptuous villas.",
  "A family-friendly community, Arabian Ranches 3 offers large villas and townhouses covered by beautifully landscaped parks and gardens. With amenities like sports facilities, community centers, and schools, it offers a peaceful suburban lifestyle.",
  "Near the Al Maktoum International Airport, Emaar South is a master-planned community with a variety of housing options, including townhouses and apartments. It meets the varied lifestyle needs of the residents with parks, shops, and a golf course.",

  "Palm Jumeirah is a world-renowned man-made island offering exclusive waterfront living with breathtaking views of the Arabian Gulf. It features luxury villas, apartments, and hotels, as well as upscale dining, shopping, and entertainment options.",
  "Dubai Creek Harbour is a waterfront development located near Dubai Creek, providing expansive views of Dubai Creek Tower and the city skyline. It creates a lively urban community with luxurious residences, waterfront promenades, and cultural attractions.",
  "Damac Lagoons is a waterfront community offering luxurious living surrounded by lush greenery and crystal-clear lagoons. It features stylish villas and apartments, as well as a range of amenities such as parks, swimming pools, and sports facilities.",
  "Sobha Hartland is an upscale residential community located along the Dubai Water Canal, offering luxurious villas and apartments. It boasts lush green spaces, waterfront living, and world-class amenities including schools, hospitals, and shopping centers.",
  "Emaar Beachfront is a beachfront community offering luxury waterfront living with private beach access and stunning views of the Arabian Gulf. It features upscale residences, hotels, and leisure facilities, creating a resort-style lifestyle experience.",
  "Located in the center of Meydan City, Meydan District One is a prominent residential development. It provides luxurious villas with incredible views of the waterfront and surrounded by lush greenery. Besides being situated close to Meydan Racecourse, residents have access to excellent amenities.",
  "Tilal Al Ghaf is a vibrant mixed-use community offering luxury villas, townhouses, and apartments surrounded by lush greenery and crystal-clear lagoons. It features a central lagoon, beach access, and a variety of leisure and recreational facilities.",
];

const CardPage = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleViewDetails = (index) => {
    setSelectedCard(index);
  };

  const handleClosePopup = () => {
    setSelectedCard(null);
  };

  const links = [
    "/DubaiDowntown#top",
    "/DubaiMarina#top",
    "/HillEstate#top",
    "/BusinessBay#top",
    "/damac-hills",
    "/al-zorah",
    "/arabian-ranches-3",
    "/emaar-south",
    "/palm-jumeirah",
    "/dubai-creek-harbour",
    "/damac-lagoons",
    "/sobha-hartland",
    "/emaar-beachfront",
    "/meydan-district-one",
    "/tilal-al-ghaf",
    null, // Ras Al Khaimah already has a link
  ];

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-center mt-8 mb-10">
        <FirstCard image={Topc16} title="Ras Al Khaimah" />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-14">
        {images.map((image, index) => (
          <div key={index} className="flex justify-center animate-fadeInUp">
            <Card
              image={image}
              title={titles[index]}
              onViewDetails={() => handleViewDetails(index)}
            />
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-8">
        <LastCard
          image={Topc15}
          title="Jumeirah Village Circle"
          onViewDetails={() => handleViewDetails(images.length)}
        />
      </div>
      <div className="flex justify-center py-10">
        <Link
          to={"/contact#top"}
          type="button"
          className="text-white uppercase bg-[#D8B56D]  w-[20%]  font-medium rounded-3xl text-sm px-5 py-2.5 text-center mr-2 mb-2"
        >
          Contact
        </Link>
      </div>
      {selectedCard !== null && (
        <Popup
          image={selectedCard === images.length ? Topc15 : images[selectedCard]}
          title={
            selectedCard === images.length
              ? "Jumeirah Village Circle"
              : titles[selectedCard]
          }
          text={
            selectedCard === images.length
              ? "Jumeirah Village Circle is a family-oriented community offering a mix of villas, townhouses, and apartments. It features landscaped parks, jogging trails, and community centers. Residents enjoy a peaceful suburban lifestyle with easy access to amenities."
              : popupTexts[selectedCard]
          }
          link={links[selectedCard]}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};


export default CardPage;
